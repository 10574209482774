import React from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { useI18next } from "gatsby-plugin-react-i18next"
import ScrollAnimation from "react-animate-on-scroll"

import "./CardSmartLink.less"

const CardSmartLink = ({ image, title, desc, buttonURL }) => {
  const { t } = useI18next()

  return (
    <ScrollAnimation
      className="card-smart"
      animateIn="fadeInUp"
      animateOnce={true}
    >
      <img src={image} alt={title} />
      <div className="card-smart-inner">
        <h3 dangerouslySetInnerHTML={{ __html: title }} />
        <p dangerouslySetInnerHTML={{ __html: desc }} />
        <Button href={buttonURL} type="link" target="_blank">
          {t("More")}
        </Button>
      </div>
    </ScrollAnimation>
  )
}

CardSmartLink.propTypes = {
  image: PropTypes.string,
  imageRetina: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  buttonURL: PropTypes.string,
}

export default CardSmartLink
