import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link, useI18next } from "gatsby-plugin-react-i18next"
import { Row, Col, Button, Modal } from "antd"

import Container from "../Container"

import "./LiveVideo.less"

const LiveVideo = ({ title, image, desc, video_url }) => {
  const { t } = useI18next()
  const [visible, setVisible] = useState(false)

  return (
    <div className="live-video">
      <Container>
        <Row gutter={40} style={{ margin: "0 -15px" }}>
          <Col span={24} md={12} style={{ padding: "0 15px" }}>
            <button
              style={{
                cursor: "pointer",
                border: 0,
                background: "transparent",
                width: "100%",
              }}
              onClick={() => setVisible(true)}
            >
              <img src={image} alt={title} />
            </button>
          </Col>
          <Col span={24} md={12} style={{ padding: "0 15px" }}>
            <h3 className="section-subtitle">Live Video</h3>
            <h4 className="section-title">{title}</h4>
            <div dangerouslySetInnerHTML={{ __html: desc }} />
            {
              // <Row gutter={40} className="meta">
              //   <Col span={24} md={12}>
              //     <span>Keynote Speaker</span>
              //     <h5 className="small-title">{props.speaker}</h5>
              //     <span>{props.speakerTitle}</span>
              //   </Col>
              //   <Col span={24} md={12}>
              //     <span>Waktu</span>
              //     <h5 className="small-title">{props.date}</h5>
              //     <span>{props.time}</span>
              //   </Col>
              // </Row>
            }
            <Button size="large">
              <Link to="/informasi/live/">{t("Lihat Live Video")}</Link>
            </Button>
          </Col>
        </Row>
      </Container>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        className="video-modal"
        title={title}
      >
        <iframe
          title={title}
          width="700"
          height="500"
          src={`https://www.youtube.com/embed/${video_url}`}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal>
    </div>
  )
}

LiveVideo.propTypes = {
  desc: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  speaker: PropTypes.string,
  speakerTitle: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  video_url: PropTypes.string,
}

export default LiveVideo
