import React, { useRef } from "react"
import SwiperCore, { Navigation, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import PropTypes from "prop-types"

import CardNews from "../CardNews"

import "./NewsWithSlider.less"

SwiperCore.use([Navigation, A11y])

const NewsWithSlider = ({ news }) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  return (
    <>
      <div ref={prevRef} className="custom-swiper-button-prev">
        &#8249;
      </div>
      <div ref={nextRef} className="custom-swiper-button-next">
        &#8250;
      </div>

      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onSwiper={swiper => {
          // Ensure Swiper has been initialized before updating the navigation
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.update()
        }}
      >
        {news.slice(0, 6).map((item, i) => (
          <SwiperSlide key={`news-${i}`}>
            <CardNews
              title={item.title}
              category={item.news_category}
              image={item.image}
              url={item.slug}
              date={item.created_at}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

NewsWithSlider.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      news_category: PropTypes.string,
      title: PropTypes.string,
      slug: PropTypes.string,
      created_at: PropTypes.string,
    })
  ),
}

export default NewsWithSlider
