import React, { useRef } from "react"
import PropTypes from "prop-types"
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { Button, Row, Col, Typography, Tag } from "antd"
import { useI18next } from "gatsby-plugin-react-i18next"

import Container from "../Container"

import "./StorySlider.less"

SwiperCore.use([Navigation, Pagination, A11y, Autoplay])

const StorySlider = ({ sliderContent }) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const { Title, Text, Paragraph } = Typography
  const { t } = useI18next()

  return (
    <>
      <div ref={prevRef} className="custom-swiper-button-prev">
        &#8249;
      </div>
      <div ref={nextRef} className="custom-swiper-button-next">
        &#8250;
      </div>

      <Swiper
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        slidesPerView={1}
        className="story-slider-container"
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        spaceBetween={6}
        breakpoints={{
          768: {
            slidesPerView: "auto", // Auto slides per view for mobile screens
          },
        }}
        onSwiper={swiper => {
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current

          swiper.navigation.update()
        }}
      >
        {sliderContent.map((item, i) => {
          const bgColor =
            item.news_category === "Awardeestory" ? "#FF6900" : "#0051AB"

          const linkTo =
            item.news_category === "Awardeestory"
              ? "/awardee/kontribusi"
              : "/grantees/kisah-inspiratif-rispro/"

          return (
            <SwiperSlide key={i} className="story-slider">
              <div className="story-slider-wrapper">
                <div
                  className="story-slider-image"
                  style={{ backgroundImage: `url(${item.image})` }}
                />
                <div
                  className="story-slider-content"
                  style={{ backgroundColor: bgColor }}
                >
                  <Container>
                    <Row>
                      <Col md={24}>
                        <div style={{ marginBottom: 12 }}>
                          <Tag>
                            <Link className="tag-link" to={linkTo}>
                              {item.news_category}
                            </Link>
                          </Tag>
                          <Text>{item.created_at}</Text>
                        </div>
                        <Title level={2}>{item.title}</Title>
                        <Paragraph
                          style={{ marginBottom: 12 }}
                          className="description"
                        >
                          {item.description}
                        </Paragraph>
                        <Button className="btn-outline-white" size="large">
                          <Link to={`/informasi/berita/${item.slug}`}>
                            {t("Selengkapnya")}
                          </Link>
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  )
}

StorySlider.propTypes = {
  sliderContent: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      news_category: PropTypes.string,
      title: PropTypes.string,
      slug: PropTypes.string,
      created_at: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default StorySlider
