import React from "react"
import PropTypes from "prop-types"
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Button, Row, Col, Typography } from "antd"
import _orderBy from "lodash/orderBy"

import Container from "../Container"

import "./MainSlider.less"

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const MainSlider = props => {
  const orderedSlider = _orderBy(props.sliderContent, ["order"], "asc")
  const { Title } = Typography

  return (
    <Swiper
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      loop={true}
      slidesPerView={1}
      pagination={{ clickable: true }}
      className="main-slider-container"
    >
      {orderedSlider.length > 0 &&
        orderedSlider.map((item, i) => (
          <SwiperSlide key={i}>
            <div className="main-slider">
              <div
                className="main-slider-image hide-mobile"
                style={{ backgroundImage: `url(${item.banner_image})` }}
              />
              <div
                className="main-slider-image-mobile show-mobile"
                style={{
                  backgroundImage: `url(${item.banner_image_mobile})`,
                }}
              />
              <div className="main-slider-caption">
                <Container>
                  <Row>
                    <Col md={12}>
                      <Title style={{ color: item.text_color }} level={2}>
                        {item.title}
                      </Title>
                      <Button href={item.button_url} size="large">
                        {item.button_text}
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  )
}

MainSlider.propTypes = {
  sliderContent: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      imageMobile: PropTypes.string,
      caption: PropTypes.string,
      captionColor: PropTypes.string,
      buttonText: PropTypes.string,
      buttonURL: PropTypes.string,
    })
  ),
}

MainSlider.defaultProps = {
  sliderContent: PropTypes.shape({
    captionColor: "#000",
    buttonText: "Lebih Lanjut",
  }),
}

export default MainSlider
