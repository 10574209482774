import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { PlayCircleFilled } from "@ant-design/icons"
import { Modal, Button } from "antd"
import YouTube from "react-youtube"

import "./SectionWithVideo.less"

const SectionWithVideo = ({ videoBg, videoId, playBtnText, children }) => {
  const [visible, setVisible] = useState(false)
  const videoIframe = useRef(null)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
    videoIframe.current.internalPlayer.pauseVideo()
  }

  const opts = {
    height: "390",
    width: "100%",
    playerVars: {
      autoplay: 1,
      rel: 0,
      modestbranding: 0,
      showinfo: 0,
    },
  }

  return (
    <div className="section-with-video" style={{ overflow: "hidden" }}>
      <div className="section-video-bg">
        <div
          className="section-video-bg-image"
          style={{ backgroundImage: `url(${videoBg})` }}
        >
          <Button
            onClick={showModal}
            icon={<PlayCircleFilled />}
            className="btn-outline-primary"
            size="large"
          >
            {playBtnText}
          </Button>

          <Modal
            className="video-modal"
            visible={visible}
            onCancel={handleCancel}
            footer={false}
          >
            <YouTube ref={videoIframe} videoId={videoId} opts={opts} />
          </Modal>
        </div>
      </div>

      <div className="section section-video-content">{children}</div>
    </div>
  )
}

SectionWithVideo.propTypes = {
  children: PropTypes.node,
  videoBg: PropTypes.string,
  videoId: PropTypes.string,
  playBtnText: PropTypes.string,
}

export default SectionWithVideo
